import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isBrowser } from "react-device-detect";

const RootContainer = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  overflowX: "hidden",
});

const StyledMessageContainer = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
});

export const MainContainer: FC<{ children?: React.ReactNode }> = ({
  children,
  ...props
}) => {
  let content = null;

  if (!isBrowser) {
    content = (
      <StyledMessageContainer>
        <Typography
          sx={{
            textAlign: "center",
            verticalAlign: "middle",
          }}
          variant="h2"
        >
          Sorry, you can not use this dashboard in a mobile device
        </Typography>
      </StyledMessageContainer>
    );
  } else if (children) {
    content = <>{children};</>;
  }

  return <RootContainer {...props}>{content}</RootContainer>;
};
