import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Root } from "./app/Root";
import { Login } from "./app/auth/Login";
import { AuthGuard } from "./guards/Auth";
import { AuthProvider } from "./context/auth-context";
import { ContentModalProvider } from "./context/content-modal-context";
import { ContentListProvider } from "./context/content-list-context";
import { Verify } from "./app/auth/Verify";
import { Dashboard } from "./app/dashboard";
import { ContentModal } from "./components/ContentModal";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ContentListProvider>
          <ContentModalProvider>
            <ContentModal />
            <Routes>
              <Route
                path=""
                element={
                  <AuthGuard requiresUser>
                    <Root />
                  </AuthGuard>
                }
              />
              <Route
                path="auth/login"
                element={
                  <AuthGuard requiresUnauthorized>
                    <Login />
                  </AuthGuard>
                }
              />
              <Route
                path="auth/verify"
                element={
                  <AuthGuard requiresUnauthorized>
                    <Verify />
                  </AuthGuard>
                }
              />
              <Route
                path="dashboard"
                element={
                  <AuthGuard requiresUser>
                    <Dashboard />
                  </AuthGuard>
                }
              />
            </Routes>
          </ContentModalProvider>
        </ContentListProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
