export const padWithZero = (num: number | string) => {
  if (typeof num === "string") {
    num = Number(num);
  }

  return num.toString().padStart(2, "0");
};

export const toDateString = (date: Date) => {
  return `${date.getFullYear()}-${padWithZero(
    date.getMonth() + 1
  )}-${padWithZero(date.getDate())}`;
};

export const toDateOnly = (date: Date) => {
  const dateString = toDateString(date);

  return Number(dateString.split("-").join(""));
};

export const getListOfNext365Days = (today = new Date()) => {
  const days = [];

  for (let i = 0; i < 365; i++) {
    const date = new Date(today);

    date.setDate(today.getDate() + i);

    days.push(date);
  }

  return days;
};

export const getAllDaysOfCurrentYear = (
  currentYear: number = new Date().getFullYear()
): Date[] => {
  const firstDayOfTheYear = new Date(`${currentYear}-01-01`);

  const days: any = [firstDayOfTheYear];

  let count = 0;

  while (true) {
    count++;

    const nextDay = new Date(
      days[days.length - 1].getTime() + 24 * 60 * 60 * 1000
    );

    const nextMonth = nextDay.getMonth();
    const nextDayDate = nextDay.getDate();

    days.push(nextDay);

    // if 31st of December
    if (nextMonth === 11 && nextDayDate === 31) {
      break;
    }

    if (count > 366) {
      break;
    }
  }

  return days;
};
