import React from "react";
import { Switch, SwitchProps, FormControlLabel } from "@mui/material";
import { useContentList } from "../../context/content-list-context";

export interface NotCompletedYetSwitchProps {
  onSelectToggle: (checked: boolean) => void;
}

export const NotCompletedYetSwitch: React.FC<SwitchProps> = ({ ...props }) => {
  const { onFilterChange, showOnlyPendingFilter } = useContentList();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFilterChange("showOnlyPending", event.target.checked);
  };

  return (
    <FormControlLabel
      value="end"
      sx={{
        color: "black",
      }}
      control={
        <Switch
          {...props}
          checked={showOnlyPendingFilter}
          onChange={(event) => {
            handleChange(event);
          }}
        />
      }
      label="Show only incomplete"
      labelPlacement="end"
    />
  );
};
