import { Model } from "../../types";

export interface ImageUpload {
  ETag: string;
  Location: string;
  key: string;
  Key: string;
  Bucket: string;
}

export enum CONTENT_TYPE {
  verse = "verse",
  prayer = "prayer",
  reflection = "reflection",
}

export const contentTypes = Object.values(CONTENT_TYPE);

export interface Content extends Model {
  type: CONTENT_TYPE;
  title: string | null;
  source: string | null;
  author: string | null;
  mainText: string | null;
  secondaryText: string | null;
  imageUrl: string;
  date: Date;
  completed?: boolean;
  likes?: number;
}

export interface Verse extends Content {
  source: string;
  mainText: string;
}
export interface Prayer extends Content {
  author: string;
  mainText: string;
}
export interface Reflection extends Content {
  mainText: string;
}
