import React from "react";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { YearSelector } from "./YearSelector";
import { NotCompletedYetSwitch } from "./NotCompletedYetSwitch";
import { FromTodayOnwardsSwitch } from "./FromTodayOnwardsSwitch";
import { styled } from "@mui/material";
import { useAuth } from "../../context/auth-context";

const StyledLogo = styled(Logo)(({ theme }) => ({
  width: "60px",
  height: "60px",
  minWidth: "60px",
  minHeight: "60px",
}));

const SyledAppBar = styled(AppBar)(({ theme }) => ({
  position: "fixed",
  backgroundColor: "white",
  height: "62px",
  boxShadow: "0 4px 2px -2px rgba(0, 0, 0, 0.25)",
}));

const StyledLogoText = styled(Typography)(({ theme }) => ({
  fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
  color: "black",
  fontWeight: 700,
  paddingLeft: "0px",
  letterSpacing: ".3rem",
  textDecoration: "none",
}));

const StyledAvatarContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  justifyContent: "flex-end",
}));

const StyledYearSelectorContainer = styled(Box)(({ theme }) => ({
  marginLeft: "48px",
}));

interface SettingsItem {
  title: string;
  onClick: () => void;
}

export interface TopBarProps {}

export const TopBar: React.FC<TopBarProps> = () => {
  const { user, isBootstrapping, logout } = useAuth();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const settings: SettingsItem[] = [
    {
      title: "Log out",
      onClick: React.useCallback(() => {
        logout();
      }, [logout]),
    },
  ];

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (isBootstrapping) {
    return null;
  }

  return (
    <SyledAppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <StyledLogo />
          <StyledLogoText variant="h6" noWrap>
            ECONDITION
          </StyledLogoText>

          <Stack direction="row" gap={2}>
            <StyledYearSelectorContainer>
              <YearSelector />
            </StyledYearSelectorContainer>

            <NotCompletedYetSwitch />
            <FromTodayOnwardsSwitch />
          </Stack>

          <StyledAvatarContainer>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user?.username ?? "D"} src={user?.profileImage} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(({ title, onClick }, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    onClick();
                  }}
                >
                  <Typography textAlign="center">{title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </StyledAvatarContainer>
        </Toolbar>
      </Container>
    </SyledAppBar>
  );
};
