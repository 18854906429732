import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  Content,
  updateContent as patchContent,
  createContent,
} from "../../services/content";
import { padWithZero, toDateString, toDateOnly } from "../../services/date";

interface ContentModalContextType {
  open: boolean;
  dateString: string;
  isPast: boolean;
  saving: boolean;
  contentDay: Date | null;
  content: Content | null;
  contentType: string | null;
  handleClose: () => void;
  handleSave: () => Promise<void>;
  handleOpen: (type: string, day: Date, contentToOpen?: Content) => void;
  updateContent: (updatedContentField: Partial<Content>) => void;
  upsertContent: (content: Partial<Content>) => Promise<void>;
}

export const ContentModalContext = React.createContext<
  ContentModalContextType | undefined
>(undefined);

export const ContentModalProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [isPast, setIsPast] = useState<boolean>(false);
  const [dateString, setDateString] = useState<string>("NO DATE");
  const [contentType, setContentType] = useState<string | null>(null);
  const [contentDay, setContentDay] = useState<Date | null>(null);
  const [content, setContent] = useState<Content | null>(null);
  const [open, setOpen] = useState(false);

  const upsertContent = useCallback(async () => {
    if (content) {
      setSaving(true);

      let updatedContent: Content = content;

      const feelGoodPromise = new Promise((resolve) =>
        setTimeout(resolve, 1000)
      );

      const doUpsert = async () => {
        if (content.id) {
          updatedContent = await patchContent(content);
        } else {
          updatedContent = await createContent({
            ...content,
            type: (contentType || "") as any,
            date: toDateString(contentDay as any) as any,
          });
        }
      };

      await Promise.all([feelGoodPromise, doUpsert()]);

      setContent(updatedContent);

      setSaving(false);
    }
  }, [content, contentDay, contentType]);

  const handleOpen = useCallback(
    (type: string, day: Date, contentToOpen?: Content) => {
      setContentType(type);
      setContentDay(day);
      setContent(contentToOpen || null);

      setOpen(true);
    },
    []
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSave = useCallback(async () => {
    await upsertContent();
  }, [upsertContent]);

  const updateContent = useCallback(
    (updatedContentField: Partial<Content>) => {
      let newContent: Content;

      if (content) {
        newContent = { ...content, ...updatedContentField };
      } else {
        newContent = { ...updatedContentField } as Content;
      }

      setContent(newContent);
    },
    [content]
  );

  useEffect(() => {
    if (contentDay) {
      const today = new Date();
      const [year, month, day] = toDateString(contentDay).split("-");

      const todayDateonly = toDateOnly(today);
      const contentDateonly = toDateOnly(contentDay);

      const isInThePast = todayDateonly > contentDateonly;

      setDateString(`${padWithZero(month)}/${padWithZero(day)}/${year}`);

      setIsPast(isInThePast);
    }
  }, [contentDay]);

  return (
    <ContentModalContext.Provider
      value={{
        open,
        dateString,
        isPast,
        handleOpen,
        handleClose,
        content,
        contentDay,
        contentType,
        updateContent,
        upsertContent,
        handleSave,
        saving,
      }}
    >
      {children}
    </ContentModalContext.Provider>
  );
};
