import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { styled } from "@mui/material";
import { CircularProgress, Box, Alert, Grow } from "@mui/material";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { MainContainer } from "../../components/Main";
import { LargeActiveButton } from "../../components/Button";
import * as authServices from "../../services/auth";
import { useAuth } from "../../context/auth-context";

const VerifyContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});

const FormContainer = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "400px",
  height: "400px",
});

const CodeInput = styled(MuiOtpInput)({
  marginTop: 18,
  marginBottom: 6,
  height: 70,
});

const StyledAlert = styled(Alert)({
  position: "absolute",
  width: "100%",
  maxWidth: "368px",
  top: -38,
});

const CODE_LENGTH = 6;

const matchIsNumeric = (text: string | number): boolean => {
  const isNumber = typeof text === "number";
  const isString = typeof text === "string";

  return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
};

interface LoginNavigationState {
  phone: string;
  countryCode: string;
}

export const Verify: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { refreshAuth, user, getUserFromToken } = useAuth();
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const [navigationState, setNavigateState] =
    React.useState<LoginNavigationState>({
      phone: "",
      countryCode: "",
    });
  const [code, setCode] = React.useState("");
  const [validationError, setValidationError] = React.useState<boolean>(false);
  const [validationErrroMessage, setValidationErrorMesssage] =
    React.useState<string>("");
  const [verifyingSms, setVerifyinSms] = React.useState<boolean>(false);
  const [successful, setSuccessful] = React.useState<boolean>(false);

  useEffect(() => {
    if (!location.state) {
      navigate("/auth/login");

      return;
    }

    const { phone, countryCode } = location.state as LoginNavigationState;

    if (!phone || !countryCode) {
      navigate("/auth/login");

      return;
    }

    setNavigateState({ phone, countryCode });
  }, [location, navigate]);

  const resetError = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = null;

      setValidationError(false);
    }
  }, []);

  const resetInput = useCallback(() => {
    setCode("");
  }, []);

  const handleCodeChange = useCallback(
    (code: string) => {
      resetError();

      setCode(code);
    },
    [resetError]
  );

  const showError = useCallback(
    (message: string) => {
      setValidationError(true);
      setValidationErrorMesssage(message);

      timeoutRef.current = setTimeout(() => {
        resetError();
      }, 5000);
    },
    [resetError]
  );

  const handleVerifyButtonClick = useCallback(async () => {
    const isInvalid = !code || code.length !== CODE_LENGTH;

    if (isInvalid) {
      setValidationError(true);
      setValidationErrorMesssage("Please enter a valid code.");

      timeoutRef.current = setTimeout(() => {
        resetError();
      }, 5000);

      return;
    }

    setVerifyinSms(true);

    try {
      const results = await authServices.logIn({
        code,
        countryCode: navigationState.countryCode,
        phone: navigationState?.phone,
      });

      if (results.access_token) {
        const { error, user } = await getUserFromToken(results.access_token);

        if (error) {
          showError(error.message);

          setVerifyinSms(false);

          return;
        }

        if (user && !user.isAdmin) {
          showError("You are not an admin, access denied");

          setVerifyinSms(false);

          return;
        }

        await refreshAuth(results.access_token);
      } else {
        showError("Something wrong happened, please try again later.");

        setVerifyinSms(false);
      }
    } catch (error) {
      showError((error as any).message);

      setVerifyinSms(false);
    }
  }, [
    code,
    showError,
    resetError,
    refreshAuth,
    navigationState,
    getUserFromToken,
  ]);

  useEffect(() => {
    if (user && verifyingSms) {
      setVerifyinSms(false);
      setSuccessful(true);
      resetError();
      resetInput();
    }
  }, [user, verifyingSms, resetInput, resetError]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        handleVerifyButtonClick();
      }
    },
    [handleVerifyButtonClick]
  );

  return (
    <MainContainer>
      <VerifyContainer>
        <Logo />

        <FormContainer>
          <Grow in={validationError}>
            {
              <StyledAlert severity="error">
                {validationErrroMessage}
              </StyledAlert>
            }
          </Grow>
          <Grow in={successful}>
            {
              <StyledAlert severity="success">
                {`Successfully logged in as +${user?.countryCode}${user?.phone}`}
              </StyledAlert>
            }
          </Grow>
          <CodeInput
            ref={inputRef}
            value={code}
            autoFocus
            onChange={handleCodeChange}
            onKeyDown={handleKeyPress}
            validateChar={matchIsNumeric}
            length={CODE_LENGTH}
            sx={{
              borderColor: "red",
            }}
          />
          <LargeActiveButton onClick={handleVerifyButtonClick}>
            {verifyingSms ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Verify Code"
            )}
          </LargeActiveButton>
        </FormContainer>
      </VerifyContainer>
    </MainContainer>
  );
};
