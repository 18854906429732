import React, { useCallback } from "react";

import { CircularProgress, Box } from "@mui/material";

import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { MuiTelInput, MuiTelInputInfo, matchIsValidTel } from "mui-tel-input";
import { styled } from "@mui/material";
import { MainContainer } from "../../components/Main";
import { LargeActiveButton } from "../../components/Button";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../context/auth-context";
import * as authServices from "../../services/auth";
import { useNavigate } from "react-router-dom";

const LoginContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "400px",
  height: "400px",
});

const PhoneInput = styled(MuiTelInput)({
  marginTop: 24,
  height: 70,
});

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const [formattedPhone, setFormattedPhone] = React.useState("");
  const [phone, setPhone] = React.useState<string | null>();
  const [countryCode, setCountryCode] = React.useState<string | null>();
  const [validationError, setValidationError] = React.useState<boolean>(false);
  const [sendingSms, setSendingSms] = React.useState<boolean>(false);

  const handlePhoneChange = useCallback(
    (newFormattedPhone: string, info: MuiTelInputInfo) => {
      setValidationError(false);

      const { countryCallingCode, nationalNumber } = info;

      setPhone(nationalNumber);
      setCountryCode(countryCallingCode);
      setFormattedPhone(newFormattedPhone);
    },
    []
  );

  const handleLoginButtonClick = useCallback(async () => {
    const isValid = matchIsValidTel(formattedPhone);

    if (!isValid || !phone || !countryCode) {
      setValidationError(true);

      return;
    }

    setSendingSms(true);

    // TODO Handle error
    await authServices.signOn({ phone, countryCode });

    setSendingSms(false);

    navigate("/auth/verify", {
      state: { phone, countryCode },
    });
  }, [phone, countryCode, formattedPhone, navigate]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        handleLoginButtonClick();
      }
    },
    [handleLoginButtonClick]
  );

  return (
    <MainContainer>
      <LoginContainer>
        <Logo />

        <FormContainer>
          <PhoneInput
            autoFocus
            value={formattedPhone}
            onChange={handlePhoneChange}
            defaultCountry="US"
            onlyCountries={["US", "BR", "CA"]}
            error={validationError}
            placeholder="Type your phone number"
            onKeyDown={handleKeyPress}
            disabled={sendingSms}
          />
          <LargeActiveButton onClick={handleLoginButtonClick}>
            {sendingSms ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Send SMS"
            )}
          </LargeActiveButton>
        </FormContainer>
      </LoginContainer>
    </MainContainer>
  );
};
