import React, { useCallback, useState } from "react";
import { Box, styled } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useContentModal } from "../../context/content-modal-context";
import { ContentForm } from "./Content";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Content } from "../../services/content";
import { useContentList } from "../../context/content-list-context";

const StyledModalInnerContainer = styled(Box)({
  flexDirection: "column",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxWidth: 600,
  backgroundColor: "white",
  maxHeight: 800,
  display: "flex",
  paddingLeft: 16,
  paddingRight: 16,
  paddingBottom: 48,
  borderRadius: 4,
  justifyContent: "space-around",
});

const StyledContentContainer = styled(Box)({
  flexGrow: 1,
  padding: 16,
  paddingTop: 60,
  paddingBottom: 16,
  display: "flex",
  flexDirection: "row",
});

const titleByType: Record<string, string> = {
  verse: "Verse",
  prayer: "Prayer",
  reflection: "Reflection",
};

export const ContentModal: React.FC = () => {
  const {
    handleClose,
    open,
    updateContent,
    content,
    contentType,
    dateString,
    isPast,
    handleSave,
    saving,
  } = useContentModal();

  const { fetchContent } = useContentList();

  const [isFormValid, setIsFormValid] = useState(false);

  const handleFormValidate = useCallback((isFormValid: boolean) => {
    setIsFormValid(isFormValid);
  }, []);

  const onSave = useCallback(async () => {
    await handleSave();

    await fetchContent();

    handleClose();
  }, [handleSave, fetchContent, handleClose]);

  const onContentChange = useCallback(
    (updatedField: Partial<Content>) => {
      updateContent(updatedField);
    },
    [updateContent]
  );

  let modalContent = null;

  if (contentType) {
    modalContent = (
      <>
        <ContentForm
          disabled={isPast}
          contentType={contentType}
          onContentChange={onContentChange}
          content={content}
          onValidate={handleFormValidate}
        />
      </>
    );
  }

  let title = dateString;

  if (contentType) {
    title = `${dateString} - ${titleByType[contentType]} `;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledModalInnerContainer>
        <Header title={title} onClose={handleClose} />
        <StyledContentContainer>{modalContent}</StyledContentContainer>
        <Footer
          canSubmit={isFormValid}
          saving={saving}
          alreadyPast={isPast}
          onSubmit={onSave}
        />
      </StyledModalInnerContainer>
    </Modal>
  );
};
