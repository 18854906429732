import React from "react";
import { MenuItem } from "@mui/material";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import { useContentList } from "../../context/content-list-context";

export interface YearSelectorProps {}

const firstYear = 2024;
const diffFromFirstYear = new Date().getFullYear() - firstYear;

const availableYears = Array.from(
  { length: diffFromFirstYear + 2 },
  (_, i) => firstYear + i
);

export const YearSelector: React.FC<YearSelectorProps & SelectProps> = ({
  ...props
}) => {
  const { onFilterChange, yearFilter } = useContentList();

  const handleChange = (event: SelectChangeEvent) => {
    const newYear = Number(event.target.value);

    onFilterChange("year", newYear);
  };

  return (
    <Select
      {...props}
      value={String(yearFilter)}
      onChange={handleChange as any}
      size="small"
    >
      {availableYears.map((availableYear) => (
        <MenuItem key={availableYear} value={availableYear}>
          {availableYear}
        </MenuItem>
      ))}
    </Select>
  );
};
