import { useContext } from "react";
import { ContentModalContext } from "./ContentModalContext";

export function useContentModal() {
  const context = useContext(ContentModalContext);

  if (context === undefined) {
    throw new Error(
      "The useContentModal hook can only be used inside an ContentModalContext provider."
    );
  }

  return context;
}
