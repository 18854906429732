import React, { FC, useEffect, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth-context";

export const AuthGuard: FC<{
  children?: React.ReactNode;
  requiresUser?: boolean;
  requiresUnauthorized?: boolean;
}> = ({ children, requiresUser = false, requiresUnauthorized = false }) => {
  const [canRender, setCanRender] = useState(false);
  const { isLoggedIn, isBootstrapping } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleRequiresUnauthorized = useCallback(() => {
    if (isLoggedIn) {
      navigate("/dashboard");

      return;
    }
  }, [navigate, isLoggedIn]);

  const handleUserRequired = useCallback(() => {
    if (!isLoggedIn) {
      navigate("/auth/login");

      return;
    }

    if (!location.pathname.includes("/dashboard")) {
      navigate("/dashboard");

      return;
    }

    // navigate("/dashboard");
  }, [isLoggedIn, navigate, location]);

  useEffect(() => {
    if (isBootstrapping) {
      return;
    }

    if (requiresUnauthorized) {
      handleRequiresUnauthorized();
    } else if (requiresUser) {
      handleUserRequired();
    }

    setCanRender(true);
  }, [
    handleUserRequired,
    requiresUser,
    isBootstrapping,
    requiresUnauthorized,
    handleRequiresUnauthorized,
  ]);

  return <>{canRender ? children : null}</>;
};
