import { styled } from "@mui/material";
import { Button as MuiButton, ButtonProps } from "@mui/material";

const styles = {
  base: {
    width: "100%",
    borderRadius: 1000,
    marginTop: 12,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    color: "white",
  },
  activeGradient: {
    background:
      "radial-gradient(95.11% 239.38% at 26.42% 117.97%, #C243FE 0%, #FF773D 80.98%)",
  },
};

export const LargeActiveButton = styled(MuiButton)(({ theme }) => {
  return {
    ...styles.base,
    ...styles.activeGradient,
    height: 70,
  };
});

export const ActiveButton = styled(MuiButton)(({ theme }) => {
  return {
    ...styles.base,
    ...styles.activeGradient,
    height: 70,
  };
});

export const SecondaryActiveButton = styled(MuiButton)(({ theme }) => ({
  ...styles.base,
  ...styles.activeGradient,
  height: 50,
  background: "linear-gradient(272.88deg, #B268E0 4.95%, #634CFF 93.62%)",
}));

export const MenuButton = styled((props: ButtonProps) => (
  <MuiButton {...props} />
))<{
  active?: boolean;
}>(({ active }) => {
  return {
    ...styles.base,
    borderRadius: 10,
    padding: 5,
    marginTop: 0,
    height: 50,
    width: "95%",
    color: active ? "white" : "#9c27b0",
  };
});
