import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Content, getContentsBetweenDate } from "../../services/content";
import {
  getAllDaysOfCurrentYear,
  toDateString,
  toDateOnly,
} from "../../services/date";
import { useAuth } from "../auth-context";

interface DayWithContents {
  contents: Content[];
  day: Date;
}

interface ContentListContextType {
  onFilterChange: (type: string, value: any) => void;
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  yearFilter: number;
  fetchContent: () => Promise<void>;
  getPageContent: () => DayWithContents[];
  page: number;
  pageSize: number;
  pageCount: number;
  showOnlyPendingFilter: boolean;
  loadingContent: boolean;
  showOnlyFromTodayOnwardsFilter: boolean;
  daysOfYear: Date[];
  contentsPerDayMap: Record<string, Content[]>;
  daysWithContentList: DayWithContents[];
}

const contentTypes = ["verse", "prayer", "reflection"];

export const ContentListContext = React.createContext<
  ContentListContextType | undefined
>(undefined);

export const ContentListProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { isLoggedIn } = useAuth();

  const pageSize = 20;

  const [yearFilter, setYearFilter] = useState<
    ContentListContextType["yearFilter"]
  >(new Date().getFullYear());
  const [showOnlyPendingFilter, setShowOnlyPendingFilter] =
    useState<ContentListContextType["showOnlyPendingFilter"]>(false);

  const [showOnlyFromTodayOnwardsFilter, setShowOnlyFromTodayOnwardsFilter] =
    useState<ContentListContextType["showOnlyFromTodayOnwardsFilter"]>(true);

  const [daysOfYear, setDaysOfYear] = useState<
    ContentListContextType["daysOfYear"]
  >([]);
  const [contentsPerDayMap, setContentsPerDayMap] = useState<
    ContentListContextType["contentsPerDayMap"]
  >({});
  const [loadingContent, setLoadingContent] =
    useState<ContentListContextType["loadingContent"]>(false);

  const [daysWithContentList, setDaysWithContentList] = useState<
    ContentListContextType["daysWithContentList"]
  >([]);

  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);

  const onPageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      const newPageCount = Math.ceil(daysWithContentList.length / pageSize);
      const newPage = value;

      if (newPage > newPageCount) {
        setPage(newPageCount);
      } else {
        setPage(newPage);
      }
    },
    [daysWithContentList]
  );

  const getPageContent = useCallback(() => {
    const start = (page - 1) * pageSize;
    const end = start + pageSize;

    return daysWithContentList.slice(start, end);
  }, [daysWithContentList, page, pageSize]);

  const onFilterChange = useCallback((type: string, value: any) => {
    if (type === "year") {
      setYearFilter(value);
    }

    if (type === "showOnlyPending") {
      setShowOnlyPendingFilter(value);
    }

    if (type === "showOnlyFromTodayOnwards") {
      setShowOnlyFromTodayOnwardsFilter(value);
    }
  }, []);

  const fetchContent = useCallback(async () => {
    setLoadingContent(true);

    const contentsPerDay = await getContentsBetweenDate(
      new Date(`${yearFilter}-01-01`),
      new Date(`${yearFilter}-12-31`)
    );

    setContentsPerDayMap(contentsPerDay);

    setLoadingContent(true);
  }, [yearFilter]);

  useEffect(() => {
    const newPageCount = Math.ceil(daysWithContentList.length / pageSize);

    setPageCount(newPageCount);
  }, [page, daysWithContentList.length]);

  useEffect(() => {
    const allDaysOfYear = getAllDaysOfCurrentYear(yearFilter);

    setDaysOfYear(allDaysOfYear);
  }, [yearFilter]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchContent();
    }
  }, [fetchContent, isLoggedIn]);

  useEffect(() => {
    const daysToShow: ContentListContextType["daysWithContentList"] = [];

    for (const day of daysOfYear) {
      const contentsOfTheDay = contentsPerDayMap[toDateString(day)] || [];

      const today = new Date();

      const todayDateonly = toDateOnly(today);
      const dayDateDateonly = toDateOnly(day);

      if (showOnlyFromTodayOnwardsFilter && todayDateonly > dayDateDateonly) {
        continue;
      }

      if (showOnlyPendingFilter) {
        const hasAllTypes = contentTypes.every((type) =>
          contentsOfTheDay.some((content) => content.type === type)
        );

        if (hasAllTypes) {
          continue;
        }
      }

      daysToShow.push({
        day,
        contents: contentsOfTheDay,
      });
    }

    setDaysWithContentList(daysToShow);
  }, [
    page,
    daysOfYear,
    contentsPerDayMap,
    showOnlyFromTodayOnwardsFilter,
    showOnlyPendingFilter,
  ]);

  return (
    <ContentListContext.Provider
      value={{
        onFilterChange,
        yearFilter,
        showOnlyPendingFilter,
        showOnlyFromTodayOnwardsFilter,
        daysOfYear,
        contentsPerDayMap,
        loadingContent,
        daysWithContentList,
        onPageChange,
        pageSize,
        page,
        pageCount,
        getPageContent,
        fetchContent,
      }}
    >
      {children}
    </ContentListContext.Provider>
  );
};
