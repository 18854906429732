export type Config = {
  API_URL: string;
  DIGITAL_OCEAN_SPACE_ENDPOINT: string;
  S3_BUCKET_NAME: string;
};

export const config: Config = {
  API_URL:
    process.env.REACT_APP_PUBLIC_API_URL ?? "https://api.decondition.com",
  DIGITAL_OCEAN_SPACE_ENDPOINT:
    process.env.REACT_APP_PUBLIC_DIGITAL_OCEAN_SPACE_ENDPOINT ??
    "https://decondition-bucket.sfo3.cdn.digitaloceanspaces.com",
  S3_BUCKET_NAME:
    process.env.REACT_APP_PUBLIC_S3_BUCKET_NAME ?? "decondition-bucket",
};
