import React from "react";

import { styled } from "@mui/material/styles";

import { TopBar } from "../../components/TopBar";
import { MainContainer } from "../../components/Main";
import { DailyContentList } from "../../components/DailyContentList";
import { useAuth } from "../../context/auth-context";

const StyledRoot = styled("div")(({ theme }) => ({
  width: "100vw",
  display: "flex",
  backgroundColor: "white",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

export const Dashboard: React.FC = () => {
  const { isBootstrapping } = useAuth();

  if (isBootstrapping) {
    return null;
  }

  return (
    <>
      <TopBar />
      <MainContainer>
        <StyledRoot>
          <DailyContentList />
        </StyledRoot>
      </MainContainer>
    </>
  );
};
