import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import * as authServices from "../../services/auth";
import { User, getUser } from "../../services/user";

interface AuthContextType {
  user: User | null;
  accessToken?: string;
  refreshAuth: (token?: string) => Promise<void>;
  isLoggedIn: boolean;
  hasCompletedSignUp: boolean;
  isBootstrapping: boolean;
  logout: () => Promise<void>;
  getUserFromToken: (
    token: string
  ) => Promise<{ user: User | null; error: any }>;
}

export const AuthContext = React.createContext<AuthContextType | undefined>(
  undefined
);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [isBootstrapping, setIsBootstrapping] = useState<boolean>(true);

  // The user is logged in if there is an access token and a user
  const isLoggedIn = useMemo(() => {
    return !!accessToken && !!user;
  }, [accessToken, user]);

  // The user has completed sign up if they have a username and birthday
  const hasCompletedSignUp = useMemo(() => {
    return !!user?.username && !!user?.birthday;
  }, [user]);

  const getUserFromToken = useCallback(async (token: string) => {
    const decoded = jwtDecode<{ sub: string }>(token);
    try {
      const decodedUser = await getUser(decoded.sub, token);

      return { user: decodedUser, error: null };
    } catch (error) {
      return {
        user: null,
        error,
      };
    }
  }, []);

  // Log the user out
  const logout = useCallback(async () => {
    authServices.clearToken();
    setUser(null);
    navigate("/", {});
  }, [navigate]);

  // Bootstrap the auth context. This is called whenever the app starts up, and
  // whenever the refreshAuth method is called.
  const bootstrap = useCallback(async () => {
    setIsBootstrapping(true);

    // Store the access token, if it exists
    const token = authServices.getToken();
    setAccessToken(token ?? undefined);

    // If the access token exists, get the user
    if (token) {
      try {
        const { user, error } = await getUserFromToken(token);

        if (error) {
          throw error;
        }

        if (!user?.isAdmin) {
          logout();

          return;
        }

        setUser(user);
      } catch (error) {
        authServices.clearToken();
        setAccessToken(undefined);
        setUser(null);
        throw error;
      }
    }

    setIsBootstrapping(false);
  }, [getUserFromToken, logout]);

  // Refresh the auth context based on the access token provided.
  const refreshAuth = useCallback(
    async (token?: string) => {
      if (token) authServices.setToken(token);
      await bootstrap();
    },
    [bootstrap]
  );

  useEffect(() => {
    bootstrap();
  }, [bootstrap]);

  return (
    <AuthContext.Provider
      value={{
        user,
        accessToken,
        refreshAuth,
        isLoggedIn,
        hasCompletedSignUp,
        isBootstrapping,
        logout,
        getUserFromToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
