import { useContext } from "react";
import { AuthContext } from "./AuthContext";

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(
      "The useAuth hook can only be used inside an AuthContext provider.",
    );
  }

  return context;
}
