import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box, CircularProgress, styled } from "@mui/material";

export type ImageFile = { file: File; binary: string | ArrayBuffer | null };
export interface MyDropzoneProps {
  onDrop: (acceptedFiles: ImageFile[]) => void;
  uploading?: boolean;
  disabled?: boolean;
  currentFile?: string;
}

const StyledDropZoneContainer = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px dashed #ccc",
  borderRadius: 4,
  cursor: "pointer",
  minHeight: 200,
});

const StyledSuccessContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%",
});

const StyledImage = styled("img")({
  height: "100%",
  width: "100%",
  maxHeight: 450,
  objectFit: "contain",
});

export const DropZone: React.FC<MyDropzoneProps> = ({
  onDrop,
  uploading,
  currentFile,
  disabled,
}) => {
  const onDropFile = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const binary = reader.result;

          onDrop([
            {
              file,
              binary,
            },
          ]);
        };

        reader.readAsArrayBuffer(file);
      });
    },
    [onDrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFile,
    multiple: false,
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
    },
    disabled: uploading || disabled,
  });

  let content = null;

  if (uploading) {
    content = <CircularProgress size={24} />;
  } else if (currentFile) {
    content = (
      <StyledSuccessContainer>
        <StyledImage src={currentFile} alt="ImageAlt" loading="lazy" />
      </StyledSuccessContainer>
    );
  } else {
    content = (
      <>
        <input {...getInputProps()} />
        <p>Drag 'n' drop the image here, or click to select image</p>
      </>
    );
  }

  return (
    <StyledDropZoneContainer {...getRootProps()}>
      {content}
    </StyledDropZoneContainer>
  );
};
