import { useContext } from "react";
import { ContentListContext } from "./ContentListContext";

export function useContentList() {
  const context = useContext(ContentListContext);

  if (context === undefined) {
    throw new Error(
      "The useContentList hook can only be used inside an ContentListContext provider."
    );
  }

  return context;
}
